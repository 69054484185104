import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  console.log("navigator.bluetooth:",navigator.bluetooth)
  let message = "You do not have bluetooth in your browser."
  if(navigator.bluetooth && typeof navigator.bluetooth.requestDevice=="function"){
    message = "You have bluetooth availability in your browser!"
  }
  return (
    <div className="App">
      <pre>
        {message}
      </pre>
      <button onClick={()=>{
        navigator.bluetooth.requestDevice({acceptAllDevices: true}).then(device => {
          console.log("device:",device)
          alert("device "+device.id+" "+device.name+" connecting...")
          device.gatt.connect()
        }).then(server => {
  console.log("server:",server)
  if(server && server.connected){
    alert("connected!")
    // Getting Battery Service...
    return server.getPrimaryService('battery_service');
  }

})
.then(service => {
  // Getting Battery Level Characteristic...
  return service.getCharacteristic('battery_level');
})
.then(characteristic => {
  // Reading Battery Level...
  return characteristic.readValue();
})
.then(value => {
  console.log('Battery percentage is ' + value.getUint8(0));
})
.catch(error => { console.log(error); });
      }}>
        try it
      </button>
    </div>
  );
}

export default App;
